import {theme} from '@chakra-ui/core'

export default {
  ...theme,
  fonts: {
    body: 'Karla, sans-serif',
    heading: 'Karla',
    mono: 'Karla'
  },
  colors: {
    ...theme.colors,
    "background-color": 'white'
  },
};